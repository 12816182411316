/*eslint-disable*/
import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';

//import FluidImage from '../../FluidImage';

export default ({location}) => {
  //console.log("Breadcrumbs xxx", location, location.pathname.split('/'));

  let segements = [];
  const splitted = location.pathname.split('/');
  const links = splitted.map((segment, i) => {
    //console.log("xxx", segment);
    if(segment !== "") {
      segements.push(segment);
      return <li key={segment} className={ i+1  === splitted.length ? "is-active" : ""}><Link to={"/" + segements.join('/')} >{segment.toUpperCase()}</Link></li>
    }
  })

  return (

    <div className="container is-fullwidth">
      <section className="section section-small">
          <nav className="breadcrumb breadcrumb-back is-small is-letter-spacing is-uppercase" aria-label="breadcrumbs">
              <ul>
                    <li key="home"><Link to={"/"}><span className="icon icon-arrow-left"></span>Home</Link></li>
                    {links}
                </ul>
            </nav>
        </section>
    </div>

  );
}

