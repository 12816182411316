import React from 'react';
import DefaultLayout from '../layouts/default';
import FluidImage from '../components/FluidImage.js';
import Breadcrumbs from '../components/Custom/Navigation/Breadcrumbs';

export default ({ location }) => {
//  const dispatch = useDispatch();

//   React.useEffect(() => {

//   });


  return (
    <DefaultLayout location={location}>

      <Breadcrumbs location={location} />
            
      <a id="1" className="scroll-anchor"></a>
      <div className="container is-layout">
          
        <section className="section">

          <div className="columns is-tablet is-centered">
            <div className="column is-8">
              <h1>Terms of Use.</h1>
              <p>&mdash; Ich bin ein kleiner Blindtext. Und zwar schon so lange ich denken kann.</p>
              
              <p className="is-normal mt-25">Hallo. Ich bin ein kleiner Blindtext. Und zwar schon so lange ich denken kann. Es war nicht leicht zu verstehen, was es bedeutet, ein blinder Text zu sein: Man macht keinen Sinn. Wirklich keinen Sinn. Man wird zusammenhangslos eingeschoben und rumgedreht.</p>
            </div>
          </div>

        </section>

      </div>
        
    </DefaultLayout>
  );
};

